import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useColorStore } from '../../../Store/ColorStore';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import { useLeadActionStore } from './../../../Store/LeadStore/LeadAction';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { Months, numFormatter, userSegrigation, userType } from '../../../Export';
import { useUserPermissionStore } from '../../../Store/UserPermissionStore/UserPermissionStore';
import { leadFollowUpOptions } from '../../../Export';
import { useFieldLeadStore } from '../../../Store/LeadStore/FieldLeadStore';
import { useMisStore } from '../../../Store/MisStore/MisStore';
import { usePrStore } from './../../../Store/PrStore/PrStore';
import { Creatable } from 'react-select';
import { useRenewStore } from '../../../Store/RenewStore/RenewStore';
import RenewDataList from './RenewDataList';
import { CSVLink } from 'react-csv';
import { useFieldDashboardStore } from '../../../Store/DashboardStore.js/FieldDashboardStore';

const animatedComponents = makeAnimated();
const RenewData = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { users, fetchUsers } = useUserStore()
    const { fetchRenew, renewData, setRenew, renewOther, setRenewNull, searchRenew, importRenewData } = useRenewStore()
    const { fetchRTotals, setRTotalNull,rtotals } = useFieldDashboardStore()
    const { restoreLeads, trashLeads, assignLeads } = useLeadActionStore()
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    const [searchInput, setSearchInput] = useState("")

    function search() {
        setReload(!reload)
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])

    // ------------------------------------------Permission Work---------------------------------------

    const [permission, setPermission] = useState(false)
    const [permission2, setPermission2] = useState(false)

    const { userPermissions } = useUserPermissionStore()
    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'LEADS_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission(d[x.name])
        }
    }, [userPermissions])

    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'OWNER_CHANGE_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission2(d[x.name])
        }
    }, [userPermissions])


    // ----------------Checked Ids Work---------------------------

    const [checked, setChecked] = useState([]);
    const handleCheckAllChange = (e) => {
        setChecked(
            e.target.checked ? renewData.map((c) => c.id) : []
        );
    };

    const handleSingleChange = (e, c) => {
        setChecked((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (renewOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(renewOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(renewOther.count)
        }
    }, [renewOther]);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        const newOffset = (event.selected * itemsPerPage) % renewOther.count;
        setItemOffset(newOffset);
    };

    // ******************pagination work ends here***************************************


    // *************************************************Main Url Work****************************************************

    const [fetcher, setFetcher] = useState({
        month: '', year: '', status: 'u', c_start_date: '', c_end_date: ''
    })

    useEffect(() => {
        if (location.state) {
            if (location.state.m && location.state.y && location.state.s) {
                setFetcher({ ...fetcher, month: location.state.m, year: location.state.y, status: location.state.s, c_start_date: location.state.start ? location.state.start : '', c_end_date: location.state.end ? location.state.end : '' })
            }
        }
    }, [location])

    useEffect(() => {
        if (fetcher.month.length > 0 && fetcher.year.length > 0 && fetcher.status.length > 0) {
            let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&search=${searchInput}&startDate=${fetcher.c_start_date}&endDate=${fetcher.c_end_date}`
            setIsLoading(true)
            fetchRenew(u, fetcher.month, fetcher.year, fetcher.status)
                .then(res => {
                    setIsLoading(false)
                    setRenew(res)
                })
                .catch(err => {
                    setIsLoading(false)
                })
        }
        else if (searchInput.length > 0) {
            let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&search=${searchInput}&startDate=${fetcher.c_start_date}&endDate=${fetcher.c_end_date}`
            setIsLoading(true)
            searchRenew(u)
                .then(res => {
                    setIsLoading(false)
                    setRenew(res)
                })
                .catch(err => {
                    setRenewNull([])
                    setIsLoading(false)
                })
        }
        else {
            setRenewNull([])
        }
    }, [reload, itemsPerPage, currentPage, fetcher])

    useEffect(() => {
        if (fetcher.month.length > 0 && fetcher.year.length > 0) {
            fetchRTotals(fetcher.month, fetcher.year)
        } else {
            setRTotalNull()
        }
    }, [fetcher, reload])

    // ******************************************************************************************************

    const [csvFile, setCsvFile] = useState()

    let alist = [{
        policy_number: '', updated_gross_premium: '', updated_net_premium: ''
    }]

    const handleSubmit = () => {
        if (csvFile) {
            setIsLoading(true)
            let formData = new FormData()
            formData.append('file', csvFile)
            importRenewData(formData)
                .then(res => {
                    document.getElementById('dltBtn2').click()
                    setIsLoading(false)
                    setCsvFile(null)
                    setReload(!reload)
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })
        }
    }

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                        <div className='ms-2'>
                            <button data-bs-toggle="modal" data-bs-target={"#uploadModal"} className="infoButton">Upload</button>
                        </div>
                    </div>

                    <div className="myrow">
                        <div style={{ color: color.darkText, fontSize: 12 }}>
                            Month <span className="text-danger">*</span> <br /><select value={fetcher.month} onChange={(e) => setFetcher({ ...fetcher, month: e.target.value })} className='searchInput me-1' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '85px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                                <option value="">-----Select month-----</option>
                                {Months.map((d, i) => {
                                    return <option key={i} value={i + 1}>{d.value}</option>
                                })}
                            </select>
                        </div>
                        <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                            Year <span className="text-danger">*</span><br /><select value={fetcher.year} onChange={(e) => setFetcher({ ...fetcher, year: e.target.value })} className='searchInput me-1' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '85px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                                <option value="">-----Select year-----</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                        <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                            Status <span className="text-danger">*</span><br /><select value={fetcher.status} onChange={(e) => setFetcher({ ...fetcher, status: e.target.value })} className='searchInput me-1' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '85px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                                <option value="a">All</option>
                                <option value="u">Unpaid</option>
                                <option value="p">Paid</option>
                                <option value="l">Lost</option>
                            </select>
                        </div>
                        <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                            Callback<br />
                            <input type='date' value={fetcher.c_start_date} onChange={(e) => setFetcher({ ...fetcher, c_start_date: e.target.value })} className='searchInput me-1' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '95px', padding: '4px 5px', textAlign: 'center', borderRadius: '2px' }} />
                            <input type='date' value={fetcher.c_end_date} onChange={(e) => setFetcher({ ...fetcher, c_end_date: e.target.value })} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '95px', padding: '4px 5px', textAlign: 'center', borderRadius: '2px' }} />
                        </div>
                    </div>
                </div>

                <div className="table-responsive mt-3">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                            <option value={10}>10</option>
                            <option value={1}>1</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        &nbsp; Out of - {renewOther ? renewOther.count : 0}
                    </div>
                    <div className="myrow">
                        <div style={{ color: color.darkText, fontSize: 13, marginBottom: '7px', paddingRight: '8px', borderRight: '1px solid ' + color.borderBottomColor }}>
                            Total Renewal - <span style={{ color: color.darkText }}>{numFormatter(rtotals.tra)}</span>
                        </div>
                        <div style={{ color: color.darkText, fontSize: 13, marginBottom: '7px', paddingLeft: '8px', }}>
                            Paid Renewal - <span style={{ color: color.green }}>{numFormatter(rtotals.tpa)}</span>
                        </div>
                        <div style={{ color: color.darkText, fontSize: 13, marginBottom: '7px', paddingLeft: '8px', }}>
                            Unpaid Renewal - <span style={{ color: color.yellow }}>{numFormatter(rtotals.tua)}</span>
                        </div>
                        <div style={{ color: color.darkText, fontSize: 13, marginBottom: '7px', paddingLeft: '8px', }}>
                            Lost Renewal - <span style={{ color: color.red }}>{numFormatter(rtotals.tla)}</span>
                        </div>
                    </div>
                    {checked.length > 0 &&
                        <div style={{ color: color.blue2, fontSize: "13px", marginBottom: '10px' }}>
                            {checked.length} Entries are Selected. <u onClick={() => setChecked([])} style={{ color: color.red, cursor: 'pointer' }}> Clear all </u>
                        </div>
                    }
                    <table className="table table-nowrap align-middle mb-3 mt-3 table-bordered table-striped">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                {/* <th style={{ color: color.darkText }} scope="col">Category</th> */}
                                {/* <th style={{ color: color.darkText }} scope="col">Company</th>
                                <th style={{ color: color.darkText }} scope="col">Type</th> */}
                                <th style={{ color: color.darkText }} scope="col">Status/Callback</th>
                                <th style={{ color: color.darkText }} scope="col">Proposer</th>
                                <th style={{ color: color.darkText }} scope="col">Insured Person</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Number/Duration</th>
                                {/* <th style={{ color: color.darkText }} scope="col">Policy Duration</th> */}
                                {/* <th style={{ color: color.darkText }} scope="col">Policy Status</th>
                                <th style={{ color: color.darkText }} scope="col">Source By</th>
                                <th style={{ color: color.darkText }} scope="col">Pick up By</th>
                                <th style={{ color: color.darkText }} scope="col">Sourcing Date</th>
                                <th style={{ color: color.darkText }} scope="col">Login Date</th> */}
                                <th style={{ color: color.darkText }} scope="col">Alternate Contact / Address</th>
                                {/* <th style={{ color: color.darkText, minWidth: '150px' }} scope="col">Address</th> */}
                                <th style={{ color: color.darkText }} scope="col">Product/Plan</th>
                                {/* <th style={{ color: color.darkText }} scope="col">Medical/Non-Medical</th> */}
                                <th style={{ color: color.darkText }} scope="col">Sum Assured & Gross Premium</th>
                                {/* <th style={{ color: color.darkText }} scope="col">Plan</th> */}
                                {/* <th style={{ color: color.darkText }} scope="col">Gross Premium</th> */}
                                {/* <th style={{ color: color.darkText }} scope="col">GST</th> */}
                                <th style={{ color: color.darkText }} scope="col">Net Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Remark</th>
                                <th style={{ color: color.darkText }} scope="col">Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {renewData.length > 0 ?
                                renewData.map((c, count) => {
                                    return <Suspense key={c.id} fallback={<></>}>
                                        <RenewDataList checked={checked} users={users} handleSingleChange={handleSingleChange} reload={reload} setReload={setReload} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    </Suspense>
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                            }
                        </tbody>
                    </table>
                    {renewData.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />

                </div>

            </div>

            {isLoading && <Loading />}

            {/* ------------------------------Add modal----------------------------------- */}

            <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Upload Data</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Attach File (.csv,.xlsx) <span className="text-danger">*</span></label>
                                <input type='file' value={csvFile ? csvFile.filename : ''} onChange={(e) => setCsvFile(e.target.files[0])} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            {<CSVLink data={alist} filename={"sample.csv"}><div className='text-start'> <span style={{ fontSize: '14px', color: color.blue2 }}>Click here to Download CSV Format... </span></div></CSVLink>}
                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id='dltBtn2' style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RenewData