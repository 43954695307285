import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useRenewStore = create((set) => ({
    renewData: [], renewOther: null,
    searchRenew: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/search-renew/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchRenew: async (payload, m, y, s) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-renew/' + m + '/' + y + '/' + s + '/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    setRenew: async (data) => {
        set({ renewData: data.data, renewOther: { count: Number(data.count), currentPage: Number(data.currentPage) } });
    },
    setRenewNull: async (data) => {
        set({ renewData: [], renewOther: null });
    },
    addRenewRemark: async (payload, id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/add-renew-remark/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    markDoneRemark: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/mark-done-renew/' + id + '/', null, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    lostRenew: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/mark-lost-renew/' + id + '/', null, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    importRenewData: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/upload-renew/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    }
}));